import React from 'react';
import '../../App.css';
import ProjectCards from '../ProjectCards.js'




function Projects() {
  return( 
  <>
    <ProjectCards />
  </>
  );
}

export default Projects;